import { Link } from "react-router-dom";

import FooterLinkList from "./FooterLinkList";

const Footer = () => {
    return (
        <footer className="footer">
            <p className="footer-logo-link">
                <Link to="/" className="primary-txt">RECOVERY<br />STORY</Link>
            </p>
            <FooterLinkList />
            <ul className="company-links">
                <li>
                    <Link to="/privacypolicy" className="company-link">プライバシーポリシー</Link>
                </li>
                <li>
                    <Link to="/terms" className="company-link">利用規約</Link>
                </li>
            </ul>
            <small className="copylight">© 2025 RECOVERY STORY</small>
        </footer>
    );
};
  
export default Footer;