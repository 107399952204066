type Props = {
    fullName : string;
    firstName : string;
    lastName : string;
    text : string;
};

const TalentListItem = (props: Props) => {
    const leadTxtArray = props.text.split('\n');
    const leadTxts = leadTxtArray.map((leadTxt: string, i) => (
        <span key={i}>{leadTxt}<br /></span>
    ));
    return (
        <div className="talent-info">
            <h3 className="primary-txt">
                {props.lastName}<br />
                {props.firstName}
            </h3>
            {
                props.text ? 
                <>
                    <p className="talent-txt">
                        <span>{leadTxts}</span>
                    </p>
                    <p className="talent-arrow">MORE</p>
                </>
                : null
            }
        </div>
    )
};

export default TalentListItem;