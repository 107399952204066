import { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";

import FooterLinkList from "./FooterLinkList";

const FixedContents = () => {
    const fixedContents = useRef<HTMLDivElement>(null!);
    const [fixed, setFixed] = useState(false);

    const scrollHandler = () => {
        const scrollTop = fixedContents.current.getBoundingClientRect().top;
        scrollTop <= 0 ? setFixed(true) : setFixed(false);
    }
    useEffect(() => {
        document.body.style.overflow = "auto";
        window.addEventListener('scroll', scrollHandler);
        return () => {
            window.removeEventListener('scroll', scrollHandler);
        }
    }, []);
    return (
        <div ref={fixedContents}>
            <div className={"fixed-contents-inner" + (fixed ? " isFixed" : "")}>
                <div className="fixed-contents-left">
                    <Link to="/" className="primary-txt">RECOVERY<br />STORY</Link>
                </div>
                <div className="fixed-contents-right">
                    <div>
                        <FooterLinkList />
                        <ul className="company-links">
                            <li>
                                <Link to="/privacypolicy" className="company-link">プライバシーポリシー</Link>
                            </li>
                            <li>
                                <Link to="/terms" className="company-link">利用規約</Link>
                            </li>
                        </ul>
                        <small className="copylight">© 2025 RECOVERY STORY</small>
                    </div>
                </div>
            </div>
        </div>
    )
};
  
export default FixedContents;